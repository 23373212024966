/* eslint-disable no-unused-vars */
import { createStore } from 'vuex'
import { db } from '@/firebaseConfig';

export const store = createStore({
    state: {
        blocks: [],

        dishes: [],
        dish: {},
        dishesCategories: [],
        promotions: [],
        promotion: {},
        menus: [],
        menu: {},
        rewards: [],
        award: {},

        cart: [],
        copupons: [],
        activeOrder: {},

        user: {},
        userOrders: [],
        userOrder: {}
    },
    mutations: {
        // BLOCKS
        setBlocks(state, payload){
            state.blocks = payload;
        },

        // RESTAURANT
        setDishes(state, payload){
            state.dishes = payload;
        },
        setDish(state, payload){
            state.dish = payload
        },

        setDishesCategories(state, payload){
            state.dishesCategories = payload
        },

        setPromotions(state, payload){
            state.promotions = payload
        },
        setPromotion(state, payload){
            state.promotion = payload
        },

        setMenus(state, payload){
            state.menus = payload
        },
        setMenu(state, payload){
            state.menu = payload
        },

        setRewards(state, payload){
            state.rewards = payload
        },
        setReward(state, payload){
            state.award = payload
        },
    },
    actions: {

        // BLOCKS
        async getBlocks({commit}, page){
                const data = [];
                await db.collection('/blocks/pages/' + page).orderBy('order', 'asc').get().then(res => {
                    res.forEach(item => {
                        const e = {
                        objectID: item.id,
                        ...item.data()
                        }
                        data.push(e);
                    })
                })
                .catch(err => {
                    console.log(err);
                });                
                commit('setBlocks', data);
        },

        // RESTAURANT
        // Dishes
        async getDishes({commit}){
            if(!this.state.dishes.length){

                const data = [];
                await db.collection('/evolbe-modules/restaurant/dishes').where('active', '==', true).orderBy('popularity', 'desc').get().then(res => {
                    res.forEach(item => {
                        const e = {
                        objectID: item.id,
                        ...item.data()
                        }
                        data.push(e);
                    })
                })
                .catch(err => {
                    console.log(err);
                });
                
                commit('setDishes', data);
            }
        },
        async getDish({commit}, id){
            if(!this.state.dishes.length){
                let data = {};
                await db.collection('/evolbe-modules/restaurant/dishes').doc(id).get().then(res => {
                    data = res.data();
                })
                .catch(err => {
                    console.log(err);
                });
                commit('setDish', data);
                return data;
            } else {
                const data = this.state.dishes.find(x => x.objectID === id);
                commit('setDish', data);
                return data;
            }
        },

        // Dishes Categories
        async getDishesCategories({commit}){
            if(!this.state.dishesCategories.length){

                const data = [];
                await db.collection('/evolbe-modules/restaurant/categories').where('active', '==', true).orderBy('order', 'asc').get().then(res => {
                    res.forEach(item => {
                        const e = {
                        objectID: item.id,
                        ...item.data()
                        }
                        data.push(e);
                    })
                })
                .catch(err => {
                    console.log(err);
                });
                
                commit('setDishesCategories', data);
            }
        },

        // Promotions
        async getPromotions({commit}){
            if(!this.state.promotions.length){

                const data = [];
                await db.collection('/evolbe-modules/restaurant/promotions').where('active', '==', true).orderBy('order', 'asc').get().then(res => {
                    res.forEach(item => {
                        const e = {
                        objectID: item.id,
                        ...item.data()
                        }
                        data.push(e);
                    })
                })
                .catch(err => {
                    console.log(err);
                });
                
                commit('setPromotions', data);
            }
        },
        async getPromotion({commit}, id){
            if(!this.state.promotions.length){
                let data = {};
                await db.collection('/evolbe-modules/restaurant/promotions').doc(id).get().then(res => {
                    data = res.data();
                })
                .catch(err => {
                    console.log(err);
                });
                commit('setPromotion', data);
                return data;

            } else {
                const data = this.state.promotions.find(x => x.objectID === id);
                commit('setPromotion', data);
                return data;
            }
        },

        // Menus
        async getMenus({commit}){
            if(!this.state.menus.length){

                const data = [];
                await db.collection('/evolbe-modules/restaurant/menus').where('active', '==', true).orderBy('dateCreated', 'desc').get().then(res => {
                    res.forEach(item => {
                        const e = {
                        objectID: item.id,
                        ...item.data()
                        }
                        data.push(e);
                    })
                })
                .catch(err => {
                    console.log(err);
                });
                
                commit('setMenus', data);
            }
        },
        async getMenu({commit}, id){
            if(!this.state.menus.length){
                let data = {};
                await db.collection('/evolbe-modules/restaurant/menus').doc(id).get().then(res => {
                    data = res.data();
                })
                .catch(err => {
                    console.log(err);
                });
                commit('setMenu', data);
                return data;
            } else {
                const data = this.state.menus.find(x => x.objectID === id);
                commit('setMenu', data);
                return data;
            }
        },

        // Rewards
        async getRewards({commit}){
            if(!this.state.rewards.length){

                const data = [];
                await db.collection('/evolbe-modules/restaurant/rewards').where('active', '==', true).orderBy('points', 'asc').get().then(res => {
                    res.forEach(item => {
                        const e = {
                        objectID: item.id,
                        ...item.data()
                        }
                        data.push(e);
                    })
                })
                .catch(err => {
                    console.log(err);
                });
                
                commit('setRewards', data);
            }
        },
        async getReward({commit}, id){
            if(!this.state.rewards.length){
                let data = {};
                await db.collection('/evolbe-modules/restaurant/rewards').doc(id).get().then(res => {
                    data = res.data();
                })
                .catch(err => {
                    console.log(err);
                });
                commit('setReward', data);
            } else {
                const data = this.state.rewards.find(x => x.id === id);
                commit('setReward', data);
            }
        },

        // USER
        // User



    },
    getters: {
    },
    modules: {},
});
  