import firebase from 'firebase/app';
import 'firebase/firestore'
import 'firebase/auth';

// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBh_m545L-nLr3x4Tus63-Z0CYnlfZNFuE",
  authDomain: "evolbe-87cdd.firebaseapp.com",
  databaseURL: "https://evolbe-87cdd.firebaseio.com",
  projectId: "evolbe-87cdd",
  storageBucket: "evolbe-87cdd.appspot.com",
  messagingSenderId: "949599463070",
  appId: "1:949599463070:web:caddc1021c9e4ee6e7bd8f",
  measurementId: "G-5K87QGJJTK"
};
// Initialize Firebase
firebase.initializeApp(firebaseConfig);

export const db = firebase.firestore();
firebase.firestore().settings({
  ignoreUndefinedProperties: true,
});